import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const opts = {
	theme: {
		themes: {
			light: {
				maincolor1: '#0c153b',
				maincolor2: '#232644',
			},
			dark: {
				maincolor1: '#0c153b',
				maincolor2: '#232644',
			}
		},
	},
};

export default new Vuetify(opts);
