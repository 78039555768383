import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import md5 from 'js-md5';
import global from './global/global'
import axios from './plugins/axios'
import VueSocialSharing from 'vue-social-sharing'

Vue.prototype.$md5 = md5
Vue.prototype.$global = global

Vue.use(axios)
Vue.use(VueSocialSharing)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
