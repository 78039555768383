import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '*',
		name: 'MainSplash',
		component: () => import('@/components/MainSplash')
	},
	{
		path: '/face',
		name: 'face',
		component: () => import('@/components/MainFace')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/components/MainLogin')
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('@/components/MainRegister')
	},
	{
		path: '/share',
		name: 'share',
		component: () => import('@/components/MainShare')
	},
	{
		path: '/profile',
		name: 'profile',
		component: () => import('@/components/MainProfile')
	},
	{
		path: '/wallet',
		name: 'wallet',
		component: () => import('../components/MainWallet.vue')
	},
	{
		path: '/faq',
		name: 'faq',
		component: () => import('../components/MainFaq.vue')
	},
	{
		path: '/statement',
		name: 'statement',
		component: () => import('../components/MainStatement.vue')
	},
	{
		path: '/stat',
		name: 'stat',
		component: () => import('../components/MainStat.vue')
	},
	{
		path: '/setting',
		name: 'setting',
		component: () => import('../components/MainSetting.vue')
	},
	{
		path: '/kollist',
		name: 'kollist',
		component: () => import('../components/MainKolList.vue')
	},
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router