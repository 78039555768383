<template>
  <v-app dark class="maincolor1">
    <v-main dark class="maincolor1 pa-0 ma-0">
		<router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
